import { provideStore } from '@ngrx/store';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { themeReducer } from './app/shared/state/theme/theme.reducer';
import { clearState } from './app/shared/reducers/authentication.meta.reducer';
import { environment } from './environments/environment';
import { provideEffects } from '@ngrx/effects';
import { AuthenticationEffects } from './app/shared/state/authentication/authentication.effects';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { whitelistInterceptorFn } from './app/shared/interceptors/shared-interceptors';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { Routes, provideRouter } from '@angular/router';
import { AxleLoginComponent } from './app/features/login/login/login.component';
import { AxleResetPasswordContainerComponent } from './app/features/reset-password/container/reset-password-container.component';
import { AxleHomeContainerComponent } from './app/features/home/container/container.component';
import { determineAuthRedirectTo, determineRedirectTo, parentAuthGuardFn } from './app/shared/guard/authentication.guards';
import { AxleContactsContainerComponent } from './app/features/contacts/container/axle-contacts-container.component';
import { AxleEventsContainerComponent } from './app/features/events/container/events-container.component';
import { AxleReportingContainerComponent } from './app/features/reporting/container/reporting-container.component';
import { AxleProfileContainerComponent } from './app/features/profile/container/profile-container.component';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => AxleLoginComponent
  },
  {
    path: 'password',
    children: [
      {
        path: 'reset',
        loadComponent: () => AxleResetPasswordContainerComponent
      }
    ]
  },
  {
    path: 'home',
    loadComponent: () => AxleHomeContainerComponent,
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'contacts',
    loadComponent: () => AxleContactsContainerComponent,
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'events',
    loadComponent: () => AxleEventsContainerComponent,
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'reporting',
    loadComponent: () => AxleReportingContainerComponent,
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'profile',
    loadComponent: () => AxleProfileContainerComponent,
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'auth',
    children: [],
    // component: SpinnerOverlayComponent,
    canActivate: [determineAuthRedirectTo]
  },
  {
    path: '**',
    pathMatch: 'full',
    children: [],
    canActivate: [determineRedirectTo]
  }
];

import { init, browserTracingIntegration, browserProfilingIntegration, replayIntegration } from '@sentry/angular-ivy';

init({
  dsn: 'https://4ad21a6729c420b2d3cac55ebf45161c@o4506972498952192.ingest.us.sentry.io/4506972507996160',
  integrations: [browserTracingIntegration(), browserProfilingIntegration(), replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://127.0.0.1:8443',
    'axle-dev.herokuapp.com',
    'axle-qa.herokuapp.com',
    'api.axleaccess.com'
  ],
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, {
  providers: [
    provideStore(
      {
        router: routerReducer,
        theme: themeReducer
      },
      {
        metaReducers: [clearState],
        // Include any runtime checks or other store configurations here
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    provideRouterStore(),
    provideRouter(routes),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es', 'fr'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true
        // prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),
    provideEffects([AuthenticationEffects]),
    provideAuth0(environment.auth0),
    provideHttpClient(withInterceptors([authHttpInterceptorFn, whitelistInterceptorFn])),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
    })
  ]
});
