<div
  class="text-base w-full font-normal text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
  <ul class="flex flex-wrap -mb-px">
    @for (tab of tabs; track tab) {
      <li class="me-2">
        <button
          class="inline-block p-4 pb-1 border-b-2 rounded-t-lg"
        [ngClass]="{
          'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': !tab.active,
          'text-black font-medium border-black dark:text-white dark:border-white': tab.active,
          'text-gray-400 cursor-not-allowed dark:text-gray-500': tab.disabled
        }"
          (click)="selectTab(tab)">
          {{ tab.label }}
        </button>
      </li>
    }
  </ul>
</div>
