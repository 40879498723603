<ul class="relative flex flex-row gap-x-2">
  @for (step of steps; track $index) {
    <li class="flex items-center gap-x-2 group" [ngClass]="step.index < steps.length - 1 ? 'shrink basis-0 flex-1' : ''">
      <div class="min-w-7 min-h-7 inline-flex flex-col gap-3 justify-center items-center text-xs align-middle">
        <span
          class="size-9 flex justify-center items-center flex-shrink-0 font-normal text-base rounded-full transition-colors duration-600 ease"
          [ngClass]="
            step.index <= selectedIndex
              ? 'bg-primary-500 text-white'
              : 'bg-secondary-300 text-secondary-500 dark:bg-gray-700 dark:text-white'
          ">
          @if (step.index < selectedIndex) {
            <svg
              class="w-[24px] h-[24px] text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 11.917 9.724 16.5 19 7.5" />
            </svg>
          } @else {
            {{ step.index + 1 }}
          }
        </span>
        <span
          class="ms-2 block text-xs font-normal overflow-ellipsis overflow-hidden"
          [ngClass]="
            step.index === selectedIndex ? 'text-black dark:text-white ' : 'text-secondary-500 dark:text-secondary-400'
          ">
          {{ step.title }}
        </span>
      </div>
      <div
        class="w-full h-[4px] flex-1 group-last:hidden transition-colors duration-600 ease"
        style="margin-left: -64px; margin-right: -64px; margin-top: -28px; z-index: -10"
        [ngClass]="selectedIndex > step.index ? 'bg-primary-500' : 'bg-secondary-300 dark:bg-secondary-700'"></div>
    </li>
  }
</ul>
