import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscriptionHandler } from 'src/app/shared/classes/subscription-handler';
import { AxlePrimaryButtonComponent } from 'src/app/shared/components/buttons/primary-button/axle-primary-button.component';
import { AxleSecondaryButtonComponent } from 'src/app/shared/components/buttons/secondary-button/axle-secondary-button.component';
import { Login } from 'src/app/shared/state/authentication/authentication.actions';
import { selectLoginLogoUrl } from 'src/app/shared/state/theme/theme.selectors';

@Component({
  selector: 'axle-login',
  standalone: true,
  imports: [AxlePrimaryButtonComponent, AxleSecondaryButtonComponent, CommonModule],
  templateUrl: './login.component.html'
})
export class AxleLoginComponent extends SubscriptionHandler {
  loginLogoSrc$ = this._store.select(selectLoginLogoUrl);

  constructor(
    private _router: Router,
    private _store: Store
  ) {
    super();
  }

  login() {
    this._store.dispatch(Login());
  }

  forgotPassword() {
    this._router.navigate(['password/reset']);
  }
}
