<div class="flex items-center justify-center w-svw h-svh p-6">
  <div
    class="flex justify-center items-center w-full max-w-3xl h-full max-h-[550px] bg-white rounded-lg shadow-axleBlue dark:bg-primary-950 relative p-6">
    <div class="flex flex-col items-center w-full max-w-md">
      <axle-back-button class="absolute top-3 left-2" (clicked)="login()"></axle-back-button>
      <img [src]="loginLogoSrc$ | async" alt="axle multi logo" class="mb-12 w-full max-w-28" />
      @if (resetPasswordSuccessful$ | async) {
        <axle-reset-password-success class="w-full"></axle-reset-password-success>
      } @else {
        <axle-reset-password-input
          class="w-full"
          [loading]="!!(loading$ | async)"
          (sendEmail)="sendEmail()"></axle-reset-password-input>
      }
    </div>
  </div>
</div>
