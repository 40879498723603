import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StepperStep } from 'src/app/shared/models/stepper.models';

@Component({
  selector: 'axle-stepper[selectedIndex][steps]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './axle-stepper.component.html'
})
export class AxleStepperComponent implements OnChanges {
  private _selectedIndex: number = 0;

  @Input()
  set selectedIndex(value: number) {
    this._selectedIndex = this._clampSelectedIndex(value);
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  @Input() steps!: StepperStep[];

  @Output() stepClicked = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['steps'] && !changes['steps'].isFirstChange()) {
      this._selectedIndex = this._clampSelectedIndex(this._selectedIndex);
    }
  }

  nextStep() {
    if (this._selectedIndex < this.steps.length - 1) {
      this.selectedIndex = this._selectedIndex + 1;
    }
  }

  previousStep() {
    if (this._selectedIndex > 0) {
      this.selectedIndex = this._selectedIndex - 1;
    }
  }

  private _clampSelectedIndex(value: number): number {
    const maxIndex = this.steps?.length > 0 ? this.steps.length - 1 : 0;
    return Math.min(Math.max(0, value), maxIndex);
  }
}
