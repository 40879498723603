<div class="w-full h-full flex flex-col items-center">
  <p class="text-secondary-900 dark:text-white mb-4 text-2xl">Reset Password</p>
  <p class="text-secondary-500 dark:text-secondary-300 text-lg mb-10 text-center">We will email you a reset password link</p>
  <input
    type="email"
    [formControl]="emailControl"
    autocomplete="email"
    id="password_reset_email"
    class="w-full border border-gray-300 mb-11 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    placeholder="Enter your work email" />
  <axle-primary-button
    text="Send Reset Email"
    size="large"
    class="w-full px-3"
    [disabled]="!emailControl.valid || loading"
    [loading]="loading"
    (clicked)="sendEmailClicked()"></axle-primary-button>
</div>
