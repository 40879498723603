<button
  type="button"
  class="text-white w-full flex justify-center items-center gap-3 bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-4 focus:ring-blue-300 font-normal text-base rounded-xl px-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  [ngClass]="size === 'regular' ? 'py-1' : 'py-2.5'"
  [class.cursor-not-allowed]="disabled"
  [class.opacity-60]="disabled"
  [disabled]="disabled"
  (click)="buttonClicked()">
  <p>{{ text }}</p>
  @if (loading) {
    <img
      src="https://cdn.axleaccess.com/assets/Axle_Loading_Spinner_Vecttor_White.svg"
      class="inline w-4 h-4 me-3 text-white animate-spin"
      alt="loading spinner" />
  }
</button>
