<axle-auth-container>
  <div content>
    <div class="flex flex-col justify-evenly 3xl:justify-center gap-4 lg:flex-row">
      <div
        class="w-full lg:w-1/2 xl:w-2/3 p-4 lg:max-w-2xl bg-white rounded-lg shadow-axleBlue sm:p-8 dark:bg-primary-950"
        style="max-height: calc(100vh - 110px)">
        <div class="flex items-center justify-between">
          <h5 class="text-xl font-medium leading-none text-gray-900 dark:text-white">Timeline</h5>
          <div class="flex">
            <button
              type="button"
              class="text-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
              </svg>

              <span class="sr-only">Icon description</span>
            </button>
            @if (false) {
              <button
                type="button"
                class="text-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                </svg>
                <span class="sr-only">Icon description</span>
              </button>
            }
          </div>
        </div>
        <p class="font-normal text-zinc-500 dark:text-gray-400 mb-3">All events and meetings organized by date and time</p>
        <form class="mb-4">
          <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              class="block w-full pl-9 pr-2 py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Meetings"
              required />
          </div>
        </form>
        <div class="flow-root px-3 max-h-[calc(100vh-300px)] overflow-auto">
          <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
            @for (timelineEvent of timelineEvents; track timelineEvent) {
              <li class="py-3 sm:py-4">
                <div class="flex flex-col gap-6">
                  @for (day of [1, 2]; track day) {
                    <button
                      class="w-full text-left flex items-center focus:outline-none focus:ring focus:border-blue-300 hover:bg-gray-50 dark:hover:bg-gray-800">
                      <div class="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 dark:text-white">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>
                      </div>
                      <div class="flex-1 min-w-0 ms-4">
                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white">{{ timelineEvent.header }}</p>
                        <p class="text-sm text-gray-500 truncate dark:text-gray-400">{{ timelineEvent.subheader }}</p>
                      </div>
                      <div class="inline-flex items-center text-base font-light text-gray-900 dark:text-white ml-auto">
                        {{ timelineEvent.rightSide }}
                      </div>
                    </button>
                  }
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
      <axle-widget header="Meetings" subheader="Craft an instant invite or share availability" class="lg:w-1/2">
        <div content class="flex flex-col gap-4">
          <axle-tile-button header="Invite" subheader="Send a calendar invite" icon="calendar"></axle-tile-button>
          <axle-tile-button header="Request" subheader="Share a scheduling link" icon="send"></axle-tile-button>
          <axle-tile-button header="Upload" subheader="Add past meetings outside of Axle" icon="import"></axle-tile-button>
          <cdk-accordion>
            <axle-accordion-item title="Outstanding Requests" subheader="Meeting requests that are un-confirmed">
              @for (request of outstandingRequests$ | async; track request) {
                <button
                  class="flex flex-col w-full px-5 pr-3 py-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:focus:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-950">
                  <div class="flex gap-3 items-center">
                    <img
                      class="w-10 h-10 rounded-full"
                      src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
                      alt="user photo" />
                    <div class="flex flex-col items-start">
                      <h5 class="text-md text-left font-medium tracking-tight text-gray-900 dark:text-white">
                        {{ request.header }}
                      </h5>
                      <p class="text-sm text-left font-light text-gray-700 dark:text-gray-400">
                        {{ request.subheader }}
                      </p>
                    </div>
                  </div>
                </button>
              }
            </axle-accordion-item>
          </cdk-accordion>
        </div>
      </axle-widget>
    </div>
  </div>
</axle-auth-container>
