<div class="flex items-center justify-center w-svw h-svh">
  <div class="bg-secondary-50 dark:bg-secondary-800 px-8 py-12 w-full max-w-xl flex flex-col items-center rounded-xl">
    <img [src]="loginLogoSrc$ | async" alt="axle multi logo" class="mb-12" />
    <axle-primary-button
      class="w-full mb-6 max-w-sm lg:min-w-96"
      text="Login"
      size="large"
      (clicked)="login()"></axle-primary-button>
    <axle-secondary-button
      class="w-full max-w-sm"
      text="I forgot my password"
      (clicked)="forgotPassword()"></axle-secondary-button>
  </div>
</div>
