import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AxlePrimaryButtonComponent } from '../../buttons/primary-button/axle-primary-button.component';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/shared/state/authentication/authentication.actions';
import { CommonModule } from '@angular/common';
import { selectCurrentTheme, selectDarkMode, selectHomeLogoUrl } from 'src/app/shared/state/theme/theme.selectors';
import { ToggleDarkMode } from 'src/app/shared/state/theme/theme.actions';
import { selectUrl } from 'src/app/shared/state/router/router.selectors';
import { Observable, map } from 'rxjs';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

type AxleTab = 'home' | 'contacts' | 'events' | 'reporting' | 'profile';

@Component({
  selector: 'axle-auth-container',
  standalone: true,
  imports: [AxlePrimaryButtonComponent, CommonModule, CdkMenu, CdkMenuItem, CdkMenuTrigger],
  templateUrl: './auth-container.component.html'
})
export class AxleAuthContainerComponent {
  theme$ = this._store.select(selectCurrentTheme);
  darkMode$ = this._store.select(selectDarkMode);
  logoSrc$ = this._store.select(selectHomeLogoUrl);

  isSidebarOpen: boolean = false;

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  tabConfig = [
    {
      header: 'Home',
      route: 'home',
      d: 'm4 12 8-8 8 8M6 10.5V19c0 .6.4 1 1 1h3v-3c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v3h3c.6 0 1-.4 1-1v-8.5'
    },
    {
      header: 'Contacts',
      route: 'contacts',
      d: 'M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3a2.5 2.5 0 1 1 2-4.5M19.5 17h.5c.6 0 1-.4 1-1a3 3 0 0 0-3-3h-1m0-3a2.5 2.5 0 1 0-2-4.5m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3c0 .6-.4 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z'
    },
    {
      header: 'Events',
      route: 'events',
      d: 'M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z'
    },
    {
      header: 'Reporting',
      route: 'reporting',
      d: 'M4 4v15c0 .6.4 1 1 1h15M8 16l2.5-5.5 3 3L17.3 7 20 9.7'
    }
  ];

  selectedTab$: Observable<AxleTab> = this._store.select(selectUrl).pipe(
    map((url) => {
      if (url.includes('home')) {
        return 'home';
      }

      if (url.includes('contacts')) {
        return 'contacts';
      }

      if (url.includes('events')) {
        return 'events';
      }

      return 'reporting';
    })
  );

  positions = [new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'end', overlayY: 'top' })];

  constructor(
    private _router: Router,
    private _store: Store
  ) {}

  tabChange(route: string) {
    this._router.navigate([route]);
  }

  signout() {
    this._store.dispatch(Logout());
  }

  toggleDarkMode() {
    document.documentElement.classList.toggle('dark');
    this._store.dispatch(ToggleDarkMode());
  }
}
