import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AxleCheckboxComponent } from 'src/app/shared/components/checkboxes/checkbox/axle-checkbox.component';
import { AxleAuthContainerComponent } from 'src/app/shared/components/containers/auth-container/auth-container.component';
import { AxleDatepickerComponent } from 'src/app/shared/components/datepicker/datepicker/axle-datepicker.component';
import { AxleNoteEditorComponent } from 'src/app/shared/components/note-editor/note-editor/axle-note-editor.component';
import { AxleStepperComponent } from 'src/app/shared/components/stepper/axle-stepper/axle-stepper.component';
import { AxleUnderlineTabsComponent } from 'src/app/shared/components/tab-groups/underline/axle-underline-tabs.component';
import { AxleTextareaComponent } from 'src/app/shared/components/textarea/axle-textarea/axle-textarea.component';
import { AxleToggleComponent } from 'src/app/shared/components/toggles/axle-toggle/axle-toggle.component';
import { StepperStep } from 'src/app/shared/models/stepper.models';
import { Tab } from 'src/app/shared/models/tabs.models';
import { Dialog } from '@angular/cdk/dialog';
import { AxleTestDialogComponent } from 'src/app/shared/components/dialogs/axle-test-dialog/axle-test-dialog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AxleBackButtonComponent } from 'src/app/shared/components/buttons/back-button/axle-back-button.component';

@Component({
  selector: 'axle-contacts-container',
  standalone: true,
  imports: [
    AxleAuthContainerComponent,
    AxleUnderlineTabsComponent,
    AxleToggleComponent,
    AxleStepperComponent,
    CommonModule,
    AxleCheckboxComponent,
    FormsModule,
    AxleDatepickerComponent,
    AxleTextareaComponent,
    AxleNoteEditorComponent,
    AxleTestDialogComponent,
    EditorModule,
    AxleBackButtonComponent
  ],
  templateUrl: './axle-contacts-container.component.html'
})
export class AxleContactsContainerComponent {
  tabs: Tab[] = [
    { label: 'Profile', active: true },
    { label: 'Dashboard' },
    { label: 'Settings' },
    { label: 'Contacts' },
    { label: 'Disabled', disabled: true }
  ];

  steps: StepperStep[] = [
    {
      index: 0,
      title: 'Select Contacts'
    },
    {
      index: 1,
      title: 'Meeting Availability'
    },
    {
      index: 2,
      title: 'Craft Email or Button'
    },
    {
      index: 3,
      title: 'Final Touches'
    }
  ];

  editorConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'lists link image table wordcount'
  };

  selectedIndex$ = new BehaviorSubject<number>(0);

  toggleControl = new FormControl();
  checkboxControl = new FormControl();
  textAreaControl = new FormControl();

  subjectControl = new FormControl();
  noteControl = new FormControl();

  onStepClicked() {}

  onTabSelected(selectedTab: Tab): void {
    console.log('Selected tab:', selectedTab.label);
  }

  previousStep() {
    this.selectedIndex$.next(this.selectedIndex$.value - 1);
  }

  nextStep() {
    this.selectedIndex$.next(this.selectedIndex$.value + 1);
  }

  // ----

  constructor(public dialog: Dialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open<string>(AxleTestDialogComponent, { hasBackdrop: true });

    dialogRef.closed.subscribe((result) => {
      console.log(result);
      console.log('The dialog was closed');
    });
  }
}
