import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { AxleTileButtonComponent } from 'src/app/shared/components/buttons/axle-tile-button/axle-tile-button.component';
import { AxleAuthContainerComponent } from 'src/app/shared/components/containers/auth-container/auth-container.component';
import { AxleWidgetComponent } from 'src/app/shared/components/widgets/axle-widget/axle-widget.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AxleAccordionItemComponent } from 'src/app/shared/components/accordion/axle-accordion-item/axle-accordion-item.component';

@Component({
  selector: 'axle-home-container',
  standalone: true,
  imports: [
    AxleWidgetComponent,
    AxleTileButtonComponent,
    AxleAuthContainerComponent,
    CommonModule,
    CdkAccordionModule,
    AxleAccordionItemComponent
  ],
  templateUrl: './container.component.html'
})
export class AxleHomeContainerComponent {
  timelineEvents = [
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    },
    {
      header: 'Meeting with Axle Tech',
      subheader: 'Sam Shapiro',
      rightSide: '10:00 AM'
    }
  ];

  outstandingRequests$ = of([
    {
      header: 'Meeting with Clear Street',
      subheader: 'February 14'
    },
    {
      header: 'Meeting with Clear Street',
      subheader: 'February 14'
    },
    {
      header: 'Meeting with Clear Street',
      subheader: 'February 14'
    }
  ]);
}
