import { Component, Input } from '@angular/core';

@Component({
  selector: 'axle-widget[header][subheader]',
  standalone: true,
  templateUrl: './axle-widget.component.html'
})
export class AxleWidgetComponent {
  @Input() header!: string;
  @Input() subheader!: string;
}
