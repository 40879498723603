@if (theme$ | async; as theme) {
  @if ({ selected: selectedTab$ | async }; as tabData) {
    <div class="flex relative">
      <button
        (click)="toggleSidebar()"
        aria-controls="default-sidebar"
        type="button"
        class="absolute top-2 left-2 inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span class="sr-only">Open sidebar</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button>
      <aside
        id="default-sidebar"
        class="fixed top-0 left-0 z-40 w-28 lg:w-64 h-screen transition-transform sm:bg-transparent"
        [class]="theme.bg"
        [ngClass]="{
          'translate-x-0': isSidebarOpen,
          '-translate-x-full': !isSidebarOpen,
          'sm:translate-x-0': true
        }"
        aria-label="Sidenav">
        <div class="overflow-y-auto pt-2 md:pt-11 pb-20 px-3 h-full flex flex-col justify-between">
          <ul class="space-y-2 flex flex-col items-center lg:items-start">
            <li class="flex flex-col gap-6 items-center justify-center mb-12 w-full">
              <button
                (click)="toggleSidebar()"
                aria-controls="default-sidebar"
                type="button"
                class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
              </button>
              <img class="w-16" [src]="logoSrc$ | async" alt="axle white logo" />
            </li>
            @for (tab of tabConfig; track tab) {
              <li class="ml-0 lg:ml-12">
                <button
                  (click)="tabChange(tab.route)"
                  class="flex items-center justify-center lg:justify-start p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-secondary-100 dark:hover:bg-primary-950 group">
                  <svg
                    class="w-6 h-6"
                    [ngClass]="tabData.selected === tab.route ? theme.primaryTextColor : theme.secondaryTextColor"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      [attr.d]="tab.d" />
                  </svg>
                  <span
                    class="ml-3 text-xl hidden lg:block"
                    [ngClass]="tabData.selected === tab.route ? theme.primaryTextColor : theme.secondaryTextColor"
                    >{{ tab.header }}</span
                  >
                </button>
              </li>
            }
          </ul>
          <button
            [cdkMenuTriggerFor]="menu"
            type="button"
            class="flex text-sm items-center justify-center gap-4 ml-0 lg:ml-12 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
            <span class="sr-only">Open user menu</span>
            <img
              class="w-8 h-8 rounded-full"
              src="https://axle-attachment-dev.s3.amazonaws.com/user-profile-pictures/b8efad1e-7e61-4c53-a456-341c2ae56b7d.jpeg"
              alt="user photo" />
            <p class="lg:block" [ngClass]="[theme.primaryTextColor]">Sam Shapiro</p>
          </button>
          <ng-template #menu>
            <div
              cdkMenu
              class="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul
                class="py-2 flex flex-col text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownUserAvatarButton">
                <li>
                  <button cdkMenuItem class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    Profile
                  </button>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >Settings</a
                  >
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >Admin Portal</a
                  >
                </li>
              </ul>
              <div>
                <button (click)="toggleDarkMode()" class="w-full py-1">
                  @if (darkMode$ | async) {
                    <div
                      class="flex w-full px-4 py-2 space-x-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      <img src="https://cdn.axleaccess.com/assets/Axle_Dark_Mode_Icon.svg" alt="dark mode icon" />
                      <h3 class="text-sm text-gray-500 dark:text-gray-300">Dark</h3>
                    </div>
                  } @else {
                    <div class="flex w-full space-x-3 px-4 py-2 hover:bg-gray-100">
                      <img src="https://cdn.axleaccess.com/assets/Axle_Light_Mode_Icon.svg" alt="light mode icon" />
                      <h3 class="text-sm text-gray-500 hover:text-gray-600 dark:text-gray-300">Light</h3>
                    </div>
                  }
                </button>
              </div>
              <div class="p-3">
                <axle-primary-button (click)="signout()" text="Sign out"></axle-primary-button>
              </div>
            </div>
          </ng-template>
        </div>
      </aside>
      <div class="flex-1 ml-0 sm:ml-24 lg:ml-72 pl-4 lg:pl-0 pt-16 pr-4 lg:pr-20 pb-4 h-screen">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  }
}
