import { CdkAccordionItem } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'axle-accordion-item',
  standalone: true,
  imports: [CommonModule, CdkAccordionItem],
  templateUrl: './axle-accordion-item.component.html'
})
export class AxleAccordionItemComponent {
  @Input() title: string = '';
  @Input() subheader: string = '';
  @Input() index: number = 0;
}
