import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'axle-checkbox[control]',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './axle-checkbox.component.html',
  styleUrl: './axle-checkbox.component.scss'
})
export class AxleCheckboxComponent {
  @Input() control!: FormControl;
  @Input() disabled: boolean = false;
  @Input() label?: string;
  @Input() labelPos: 'before' | 'after' = 'after';
  @Input() size: 'small' | 'default' | 'large' = 'default';
}
