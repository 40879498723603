import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Login, LoginFail, LoginSuccess, Logout, LogoutFail, LogoutSuccess } from './authentication.actions';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState, AuthService, RedirectLoginOptions } from '@auth0/auth0-angular';
import { Dialog } from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationEffects {
  constructor(
    private _actions: Actions,
    private _auth0Service: AuthService,
    private _dialog: Dialog
  ) {}

  login$ = createEffect(() =>
    this._actions.pipe(
      ofType(Login),
      switchMap(() =>
        of(null).pipe(
          switchMap(() => {
            const loginOptions: RedirectLoginOptions<AppState> = {
              authorizationParams: {
                screen_hint: 'login',
                prompt: 'login'
                // organization: 'org_0nV3K5uVB3nr70RW'
              },
              appState: {
                axleFlow: 'login',
                target: 'auth'
              }
            };

            return this._auth0Service.loginWithRedirect(loginOptions).pipe(take(1));
          }),
          map(() => LoginSuccess()),
          catchError(() => of(LoginFail()))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this._actions.pipe(
      ofType(Logout),
      switchMap(() =>
        of(null).pipe(
          switchMap(() => {
            this._dialog.closeAll();
            window.Atlas.logout();
            window.Atlas.call('identify', {
              userId: null,
              name: null,
              email: null
            });
            sessionStorage.removeItem('axle_session_token');

            return this._auth0Service
              .logout({ logoutParams: { returnTo: `${environment.auth0.authorizationParams?.redirect_uri}/login` } })
              .pipe(take(1));
          }),
          map(() => LogoutSuccess()),
          catchError(() => of(LogoutFail()))
        )
      )
    )
  );
}
