<button
  type="button"
  class="text-secondary-500 gap-3 dark:text-secondary-300 w-full focus:outline-none focus:ring-4 py-1 focus:ring-blue-300 font-normal text-base rounded-xl px-5 text-center dark:hover:bg-primary-950 dark:focus:ring-blue-800"
  [class.cursor-not-allowed]="disabled"
  [class.opacity-60]="disabled"
  [disabled]="disabled"
  (click)="buttonClicked()">
  <ng-content select="[prefix]"></ng-content>
  <p>{{ text }}</p>
  @if (loading) {
    <img
      src="https://cdn.axleaccess.com/assets/Axle_Loading_Spinner_Vecttor_White.svg"
      class="inline w-4 h-4"
      alt="loading spinner" />
  }
</button>
