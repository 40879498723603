import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AxlePrimaryButtonComponent } from 'src/app/shared/components/buttons/primary-button/axle-primary-button.component';

@Component({
  selector: 'axle-reset-password-success',
  standalone: true,
  imports: [AxlePrimaryButtonComponent],
  templateUrl: './reset-password-success.component.html'
})
export class AxleResetPasswordSuccessComponent {
  constructor(private _router: Router) {}

  login() {
    this._router.navigate(['login'], { replaceUrl: true });
  }
}
