import { APP_INITIALIZER, Component, ErrorHandler, OnInit, Renderer2 } from '@angular/core';
import { SubscriptionHandler } from './shared/classes/subscription-handler';
import { Store } from '@ngrx/store';
import { selectCurrentTheme } from './shared/state/theme/theme.selectors';
import { ChangeDarkMode } from './shared/state/theme/theme.actions';
import { Router, RouterModule } from '@angular/router';

import { Inject } from '@angular/core';

import { AxlePrimaryButtonComponent } from './shared/components/buttons/primary-button/axle-primary-button.component';
import { AxleTileButtonComponent } from './shared/components/buttons/axle-tile-button/axle-tile-button.component';
import { AxleWidgetComponent } from './shared/components/widgets/axle-widget/axle-widget.component';
import posthog from 'posthog-js';

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_B44LvJW3JBsMdNAM5rjNBXg9iCml1SACjcMsKhU6ve0', {
    api_host: 'https://app.posthog.com',
    secure_cookie: true
  });
}

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpClientModule } from '@angular/common/http';
import { AxleHomeContainerComponent } from './features/home/container/container.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Atlas: any;
  }
}

@Component({
  selector: 'axle-root',
  standalone: true,
  imports: [
    AxlePrimaryButtonComponent,
    AxleTileButtonComponent,
    HttpClientModule,
    AxleWidgetComponent,
    AxleHomeContainerComponent,
    RouterModule,
    CommonModule,
    EditorModule
  ],
  providers: [
    AuthHttpInterceptor,
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true
      })
    },
    // RefreshDataService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionHandler implements OnInit {
  theme$ = this._store.select(selectCurrentTheme);

  constructor(
    private _router: Router,
    private _store: Store,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit() {
    // this.addSubscription(
    //   this._store.select(getUserInfo).subscribe((userInfo) => {
    //     if (userInfo) {
    //       window.Atlas.call('identify', {
    //         userId: userInfo?.id,
    //         name: `${userInfo?.firstname ?? undefined} ${userInfo?.lastname ?? undefined}`,
    //         email: userInfo?.email,
    //         userHash: userInfo?.hashedValues
    //       });

    //       posthog.identify(userInfo.id);
    //     }
    //   })
    // );

    this.theme$.subscribe(({ bgLight, bgDark }) => {
      this.renderer.addClass(this.document.body, bgLight);
      this.renderer.addClass(this.document.body, bgDark);
    });

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this._store.dispatch(ChangeDarkMode({ darkMode: true }));
    }

    // Optional: Listen for changes in color scheme,
    // to switch images without reloading the page.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      this._store.dispatch(ChangeDarkMode({ darkMode: event.matches }));
      document.documentElement.classList.toggle('dark');
    });
  }

  chatButtonClicked() {
    window.Atlas.chat.openWindow();
  }
}
