import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AxlePrimaryButtonComponent } from 'src/app/shared/components/buttons/primary-button/axle-primary-button.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/shared/validators/email.validators';

@Component({
  selector: 'axle-reset-password-input[loading]',
  standalone: true,
  imports: [AxlePrimaryButtonComponent, ReactiveFormsModule],
  templateUrl: './reset-password-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AxleResetPasswordInputComponent {
  @Input() loading!: boolean;
  @Output() sendEmail = new EventEmitter<void>();

  emailControl = new FormControl('', [Validators.required, emailValidator()]);

  sendEmailClicked() {
    this.sendEmail.emit();
  }
}
