import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'axle-secondary-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './axle-secondary-button.component.html'
})
export class AxleSecondaryButtonComponent {
  @Input() loading?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() text?: string;

  @Output() clicked = new EventEmitter<void>();

  buttonClicked() {
    this.clicked.emit();
  }
}
