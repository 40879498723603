import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'axle-note-editor[subjectControl][noteControl]',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './axle-note-editor.component.html'
})
export class AxleNoteEditorComponent {
  @Input() subjectControl!: FormControl;
  @Input() noteControl!: FormControl;
}
