<div class="flex items-center justify-center h-full">
  <div class="bg-white dark:bg-secondary-700 rounded-lg shadow p-4" style="width: 17rem" tabindex="0">
    <div class="flex justify-between items-center mb-2">
      <div>
        <span class="text-lg font-bold text-gray-800 dark:text-white">{{ MONTH_NAMES[month] }}</span>
        <span class="ml-1 text-lg text-gray-600 dark:text-white font-normal">{{ year }}</span>
      </div>
      <div>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-secondary-800 p-1 rounded-full"
          (click)="prevMonth()">
          <svg
            class="h-6 w-6 text-gray-500 dark:text-white inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-secondary-800 p-1 rounded-full"
          (click)="nextMonth()">
          <svg
            class="h-6 w-6 text-gray-500 dark:text-white inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>

    <div class="flex flex-wrap mb-3 -mx-1">
      @for (day of DAYS; track day; let index = $index) {
        <div style="width: 14.26%" class="px-1">
          <div class="text-gray-800 dark:text-secondary-500 font-medium text-center text-xs">{{ day }}</div>
        </div>
      }
    </div>

    <div class="flex flex-wrap -mx-1">
      @for (blankday of blankdays; track blankday) {
        <div style="width: 14.28%" class="text-center border-none p-1 border-transparent text-sm"></div>
      }
      @for (date of no_of_days; track trackByDate; let dateIndex = $index) {
        <div style="width: 14.28%" class="px-1 mb-1">
          <div
            (click)="selectDate(date.date)"
            (keydown.enter)="selectDate(date.date)"
            tabindex="0"
            class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
            [ngClass]="{
              'bg-primary-500 text-white': isSelected(date.date),
              'text-gray-700 dark:text-white hover:bg-blue-200 dark:hover:bg-secondary-800': !isSelected(date.date)
            }">
            {{ date.day }}
          </div>
        </div>
      }
    </div>
  </div>
</div>
