import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'axle-datepicker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './axle-datepicker.component.html',
  styleUrl: './axle-datepicker.component.scss'
})
export class AxleDatepickerComponent implements OnInit {
  MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  datepickerValue!: string;
  month!: number;
  year!: number;
  no_of_days: { day: number; date: string }[] = [];
  blankdays: number[] = [];

  constructor() {}

  ngOnInit(): void {
    this.initDate();
  }

  initDate() {
    const today = new Date();
    this.month = today.getMonth();
    this.year = today.getFullYear();
    this.datepickerValue = `${this.year}-${this.month}-${new Date().getDate()}`;
    this.getMonthDays();
  }

  selectDate(date: string) {
    this.datepickerValue = date;
  }

  prevMonth() {
    if (this.month === 0) {
      this.month = 11;
      this.year--;
    } else {
      this.month--;
    }
    this.getMonthDays();
  }

  nextMonth() {
    if (this.month === 11) {
      this.month = 0;
      this.year++;
    } else {
      this.month++;
    }
    this.getMonthDays();
  }

  getMonthDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    const dayOfWeek = new Date(this.year, this.month).getDay();
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray: { day: number; date: string }[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({
        day: i,
        date: `${this.year}-${this.month}-${i}`
      });
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  isSelected(date: string) {
    return date === this.datepickerValue;
  }

  trackByDate = (index: number, item: { day: number; date: string }) => item.date;
}
