import { Component } from '@angular/core';
import { AxleAuthContainerComponent } from 'src/app/shared/components/containers/auth-container/auth-container.component';

@Component({
  selector: 'axle-reporting-container',
  standalone: true,
  imports: [AxleAuthContainerComponent],
  templateUrl: './reporting-container.component.html'
})
export class AxleReportingContainerComponent {}
