<button
  class="flex flex-col w-full px-5 pr-3 py-4 bg-gray-50 rounded-lg shadow-axleGray focus:outline-none focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:focus:ring-gray-700 hover:scale-105"
  (click)="tileClicked()">
  <div class="flex gap-3 items-center">
    <div class="w-16 h-16 rounded-full bg-white dark:bg-primary-950 flex justify-center items-center" alt="tile icon">
      @switch (icon) {
        @case ('import') {
          <ng-container *ngTemplateOutlet="importImage"></ng-container>
        }
        @case ('send') {
          <ng-container *ngTemplateOutlet="sendImage"></ng-container>
        }
        @case ('calendar') {
          <ng-container *ngTemplateOutlet="calendarImage"></ng-container>
        }
      }
    </div>
    <div class="flex flex-col items-start">
      <h5 class="text-lg font-medium tracking-tight text-gray-900 dark:text-white">{{ header }}</h5>
      <p class="text-sm text-left font-light text-gray-700 dark:text-gray-400">
        {{ subheader }}
      </p>
    </div>
  </div>
</button>

<ng-template #importImage>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    class="text-primary-500"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2 31.2a8.4 8.4 0 0 1-.886-16.752 9.602 9.602 0 0 1 18.608-4.745A10.8 10.8 0 1 1 32.4 31.2h-6v-8.609l3.103 3.103a2.4 2.4 0 0 0 3.394-3.393l-7.2-7.2a2.4 2.4 0 0 0-3.394 0l-7.2 7.2a2.4 2.4 0 0 0 3.394 3.393l3.103-3.1V31.2h-8.4Z" />
    <path d="M21.6 31.2h4.8v12a2.4 2.4 0 0 1-4.8 0v-12Z" />
  </svg>
</ng-template>

<ng-template #calendarImage>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    class="text-primary-500"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4 0.800049C9.76353 0.800049 9.15308 1.05291 8.70299 1.50299C8.25291 1.95308 8.00005 2.56353 8.00005 3.20005V5.60005H5.60005C4.32701 5.60005 3.10611 6.10576 2.20594 7.00594C1.30576 7.90611 0.800049 9.12701 0.800049 10.4V34.4C0.800049 35.6731 1.30576 36.894 2.20594 37.7942C3.10611 38.6943 4.32701 39.2 5.60005 39.2H34.4C35.6731 39.2 36.894 38.6943 37.7942 37.7942C38.6943 36.894 39.2 35.6731 39.2 34.4V10.4C39.2 9.12701 38.6943 7.90611 37.7942 7.00594C36.894 6.10576 35.6731 5.60005 34.4 5.60005H32V3.20005C32 2.56353 31.7472 1.95308 31.2971 1.50299C30.847 1.05291 30.2366 0.800049 29.6 0.800049C28.9635 0.800049 28.3531 1.05291 27.903 1.50299C27.4529 1.95308 27.2 2.56353 27.2 3.20005V5.60005H12.8V3.20005C12.8 2.56353 12.5472 1.95308 12.0971 1.50299C11.647 1.05291 11.0366 0.800049 10.4 0.800049ZM10.4 12.8C9.76353 12.8 9.15308 13.0529 8.70299 13.503C8.25291 13.9531 8.00005 14.5635 8.00005 15.2C8.00005 15.8366 8.25291 16.447 8.70299 16.8971C9.15308 17.3472 9.76353 17.6 10.4 17.6H29.6C30.2366 17.6 30.847 17.3472 31.2971 16.8971C31.7472 16.447 32 15.8366 32 15.2C32 14.5635 31.7472 13.9531 31.2971 13.503C30.847 13.0529 30.2366 12.8 29.6 12.8H10.4Z"
      fill="#3B82F6" />
  </svg>
</ng-template>

<ng-template #sendImage>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.1455 2.12713C21.946 1.72901 21.6396 1.39426 21.2607 1.16033C20.8818 0.926389 20.4453 0.80249 19.9999 0.80249C19.5546 0.80249 19.1181 0.926389 18.7392 1.16033C18.3602 1.39426 18.0539 1.72901 17.8543 2.12713L1.05434 35.7271C0.845889 36.1437 0.763187 36.612 0.816326 37.0748C0.869465 37.5376 1.05615 37.975 1.3536 38.3335C1.65106 38.692 2.04642 38.9562 2.49147 39.0939C2.93652 39.2315 3.41201 39.2367 3.85994 39.1087L15.8599 35.6791C16.3615 35.5357 16.8026 35.2327 17.1166 34.8161C17.4306 34.3995 17.6002 33.892 17.5999 33.3703V22.3999C17.5999 21.7634 17.8528 21.153 18.3029 20.7029C18.753 20.2528 19.3634 19.9999 19.9999 19.9999C20.6365 19.9999 21.2469 20.2528 21.697 20.7029C22.1471 21.153 22.3999 21.7634 22.3999 22.3999V33.3703C22.3997 33.892 22.5693 34.3995 22.8833 34.8161C23.1973 35.2327 23.6384 35.5357 24.1399 35.6791L36.1399 39.1063C36.5877 39.2346 37.0632 39.2297 37.5082 39.0924C37.9533 38.955 38.3488 38.6912 38.6465 38.3329C38.9442 37.9747 39.1312 37.5376 39.1847 37.0749C39.2383 36.6122 39.156 36.1439 38.9479 35.7271L22.1479 2.12713H22.1455Z"
      fill="#3B82F6" />
  </svg>
</ng-template>
