import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'axle-back-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './axle-back-button.component.html'
})
export class AxleBackButtonComponent {
  @Output() clicked = new EventEmitter<void>();

  buttonClicked() {
    this.clicked.emit();
  }
}
