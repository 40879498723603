<div class="flex items-center gap-2">
  @if (label && labelPos === 'before') {
    <label for="default-checkbox" class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</label>
  }
  <input
    id="default-checkbox"
    type="checkbox"
    [formControl]="control"
    [disabled]="disabled"
    class="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 rounded focus:ring-primary-300 dark:focus:bg-primary-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-secondary-600" />
  @if (label && labelPos === 'after') {
    <label for="default-checkbox" class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</label>
  }
</div>
