import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'axle-textarea[control]',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './axle-textarea.component.html',
  styleUrl: './axle-textarea.component.scss'
})
export class AxleTextareaComponent {
  @Input() placeholder: string = '';
  @Input() control!: FormControl;
  @Input() disabled: boolean = false;
}
