<axle-auth-container>
  <div content class="p-4 flex flex-col gap-4 items-start">
    <axle-underline-tabs [tabs]="tabs" (tabSelected)="onTabSelected($event)"></axle-underline-tabs>
    <axle-toggle [control]="toggleControl"></axle-toggle>
    <!-- Circle Icon Button -->
    <button
      type="button"
      class="text-primary-500 border border-primary-500 hover:bg-primary-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-primary-500 dark:text-primary-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-primary-500">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
        <path
          d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z" />
      </svg>
      <span class="sr-only">Icon description</span>
    </button>
    <axle-back-button></axle-back-button>
    <!-- Icon button -->
    <button
      type="button"
      class="text-primary-500 hover:text-primary-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:text-primary-500 dark:hover:text-white dark:focus:ring-blue-800">
      <!-- Tune Icon -->
      <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 6V4M12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10M12 6C12.5304 6 13.0391 6.21071 13.4142 6.58579C13.7893 6.96086 14 7.46957 14 8C14 8.53043 13.7893 9.03914 13.4142 9.41421C13.0391 9.78929 12.5304 10 12 10M12 10V20M6 18C6.53043 18 7.03914 17.7893 7.41421 17.4142C7.78929 17.0391 8 16.5304 8 16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14M6 18C5.46957 18 4.96086 17.7893 4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16C4 15.4696 4.21071 14.9609 4.58579 14.5858C4.96086 14.2107 5.46957 14 6 14M6 18V20M6 14V4M18 18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16C20 15.4696 19.7893 14.9609 19.4142 14.5858C19.0391 14.2107 18.5304 14 18 14M18 18C17.4696 18 16.9609 17.7893 16.5858 17.4142C16.2107 17.0391 16 16.5304 16 16C16 15.4696 16.2107 14.9609 16.5858 14.5858C16.9609 14.2107 17.4696 14 18 14M18 18V20M18 14V4"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg> -->

      <!-- X Icon -->
      <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 6L18 18M6 18L18 6L6 18Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg> -->

      <!-- Three Dot Icon -->
      <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 12H5.01M12 12H12.01M19 12H19.01M6 12C6 12.2652 5.89464 12.5196 5.70711 12.7071C5.51957 12.8946 5.26522 13 5 13C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11C5.26522 11 5.51957 11.1054 5.70711 11.2929C5.89464 11.4804 6 11.7348 6 12ZM13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12ZM20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071C19.5196 12.8946 19.2652 13 19 13C18.7348 13 18.4804 12.8946 18.2929 12.7071C18.1054 12.5196 18 12.2652 18 12C18 11.7348 18.1054 11.4804 18.2929 11.2929C18.4804 11.1054 18.7348 11 19 11C19.2652 11 19.5196 11.1054 19.7071 11.2929C19.8946 11.4804 20 11.7348 20 12Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg> -->

      <!-- Edit Icon -->
      <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 4.99992H6C5.46957 4.99992 4.96086 5.21063 4.58579 5.5857C4.21071 5.96078 4 6.46948 4 6.99992V17.9999C4 18.5304 4.21071 19.0391 4.58579 19.4141C4.96086 19.7892 5.46957 19.9999 6 19.9999H17C17.5304 19.9999 18.0391 19.7892 18.4142 19.4141C18.7893 19.0391 19 18.5304 19 17.9999V12.9999M17.586 3.58592C17.7705 3.3949 17.9912 3.24253 18.2352 3.13772C18.4792 3.0329 18.7416 2.97772 19.0072 2.97542C19.2728 2.97311 19.5361 3.02371 19.7819 3.12427C20.0277 3.22484 20.251 3.37334 20.4388 3.56113C20.6266 3.74891 20.7751 3.97222 20.8756 4.21801C20.9762 4.4638 21.0268 4.72716 21.0245 4.99272C21.0222 5.25828 20.967 5.52072 20.8622 5.76473C20.7574 6.00874 20.605 6.22942 20.414 6.41392L11.828 14.9999H9V12.1719L17.586 3.58592Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg> -->

      <!-- Plus Icon -->
      <!-- <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 5.5V10.5M10 10.5V15.5M10 10.5H15M10 10.5H5"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg> -->

      <!-- Three Dot Vertical Icon -->
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 5V5.01M12 12V12.01M12 19V19.01M12 6C11.7348 6 11.4804 5.89464 11.2929 5.70711C11.1054 5.51957 11 5.26522 11 5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89464 12.2652 6 12 6ZM12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13ZM12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19C11 18.7348 11.1054 18.4804 11.2929 18.2929C11.4804 18.1054 11.7348 18 12 18C12.2652 18 12.5196 18.1054 12.7071 18.2929C12.8946 18.4804 13 18.7348 13 19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span class="sr-only">Icon description</span>
    </button>
    <axle-stepper #stepper [steps]="steps" [selectedIndex]="(selectedIndex$ | async) ?? 0" class="w-full"></axle-stepper>
    <div class="flex justify-between w-full">
      <button (click)="stepper.previousStep()">Previous</button>
      <button (click)="stepper.nextStep()">Next</button>
    </div>
    <axle-checkbox [control]="checkboxControl" label="Cameron Hohl"></axle-checkbox>
    <axle-datepicker></axle-datepicker>

    <button (click)="openDialog()">Open dialog</button>

    <axle-textarea class="w-full" [control]="textAreaControl"></axle-textarea>

    <axle-note-editor [noteControl]="noteControl" [subjectControl]="subjectControl" class="w-full"></axle-note-editor>

    <!-- <editor [init]="editorConfig" apiKey="6v13ig9t55lhw9sbgsf78jw2ieipejyojc2y9g9nwy8iwkwt"></editor> -->

    <!-- Left Caret Icon -->
    <svg width="8" height="12" viewBox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.42188 1.40625L2.82812 6L7.42188 10.5938L6.01562 12L0.015625 6L6.01562 0L7.42188 1.40625Z" fill="#9CA3AF" />
    </svg>

    <!-- Copy Clipboard Icon -->
    <svg width="22" height="23" viewBox="0 0 22 23" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33317 5.08333H5.49984C5.01361 5.08333 4.54729 5.27649 4.20347 5.6203C3.85966 5.96412 3.6665 6.43044 3.6665 6.91667V17.9167C3.6665 18.4029 3.85966 18.8692 4.20347 19.213C4.54729 19.5568 5.01361 19.75 5.49984 19.75H14.6665C15.1527 19.75 15.619 19.5568 15.9629 19.213C16.3067 18.8692 16.4998 18.4029 16.4998 17.9167V17M7.33317 5.08333C7.33317 5.56956 7.52633 6.03588 7.87014 6.3797C8.21396 6.72351 8.68027 6.91667 9.1665 6.91667H10.9998C11.4861 6.91667 11.9524 6.72351 12.2962 6.3797C12.64 6.03588 12.8332 5.56956 12.8332 5.08333M7.33317 5.08333C7.33317 4.5971 7.52633 4.13079 7.87014 3.78697C8.21396 3.44315 8.68027 3.25 9.1665 3.25H10.9998C11.4861 3.25 11.9524 3.44315 12.2962 3.78697C12.64 4.13079 12.8332 4.5971 12.8332 5.08333M12.8332 5.08333H14.6665C15.1527 5.08333 15.619 5.27649 15.9629 5.6203C16.3067 5.96412 16.4998 6.43044 16.4998 6.91667V9.66667M18.3332 13.3333H9.1665M9.1665 13.3333L11.9165 10.5833M9.1665 13.3333L11.9165 16.0833"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>
</axle-auth-container>
