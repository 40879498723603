import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from 'src/app/shared/models/tabs.models';

@Component({
  selector: 'axle-underline-tabs[tabs]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './axle-underline-tabs.component.html'
})
export class AxleUnderlineTabsComponent {
  @Input() tabs!: Tab[];
  @Output() tabSelected = new EventEmitter<Tab>();

  selectTab(tab: Tab): void {
    if (tab.disabled) {
      return;
    }

    this.tabs.forEach((t) => (t.active = false));
    tab.active = true;
    this.tabSelected.emit(tab);
  }
}
