import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { AxlePrimaryButtonComponent } from '../../buttons/primary-button/axle-primary-button.component';

@Component({
  selector: 'axle-test-dialog',
  standalone: true,
  imports: [AxlePrimaryButtonComponent],
  templateUrl: './axle-test-dialog.component.html',
  styleUrl: './axle-test-dialog.component.scss'
})
export class AxleTestDialogComponent {
  constructor(public dialogRef: DialogRef<{ result: boolean }>) {}

  close(result: boolean) {
    this.dialogRef.close({ result });
  }
}
