import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ThemeState } from './theme.state';
import { themes } from '../../models/theme.models';

export const selectThemeState = createFeatureSelector<ThemeState>('theme');

export const selectCurrentTheme = createSelector(selectThemeState, ({ currentTheme }) => themes[currentTheme]);
export const selectDarkMode = createSelector(selectThemeState, ({ darkMode }) => darkMode);

export const selectHomeLogoUrl = createSelector(selectThemeState, ({ currentTheme, darkMode }) => {
  if (darkMode || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme)) {
    return 'https://cdn.axleaccess.com/assets/axle_dark_mode_multi_logo_home.svg';
  }

  return 'https://cdn.axleaccess.com/assets/axle_light_mode_multi_logo_home.svg';
});

export const selectLoginLogoUrl = createSelector(selectThemeState, ({ currentTheme, darkMode }) => {
  if (darkMode || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme)) {
    return 'https://cdn.axleaccess.com/assets/Axle_Login_Logo_Dark.svg';
  }

  return 'https://cdn.axleaccess.com/assets/Axle_Login_Logo_Light.svg';
});
