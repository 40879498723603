<label class="inline-flex items-center cursor-pointer gap-3" [class.mb-5]="size !== 'large'">
  <input type="checkbox" [formControl]="control" [disabled]="disabled" class="sr-only peer" />
  @if (label) {
    <span class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</span>
  }
  <div
    class="relative bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 peer-checked:bg-primary-500"
    [ngClass]="{
      'w-9 h-5 after:top-[2px] after:start-[2px] after:h-4 after:w-4': size === 'small',
      'w-11 h-6 after:top-[2px] after:start-[2px] after:h-5 after:w-5': size === 'default',
      'w-14 h-7 after:top-0.5 after:start-[4px] after:h-6 after:w-6': size === 'large'
    }"></div>
  @if (label) {
    <span class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</span>
  }
</label>
