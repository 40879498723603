import { createReducer, on } from '@ngrx/store';
import { ChangeDarkMode, ChangeTheme, ToggleDarkMode } from './theme.actions';
import { initialState } from './theme.state';

export const themeReducer = createReducer(
  initialState,
  on(ChangeTheme, (state, { themeKey: currentTheme }) => ({ ...state, currentTheme })),
  on(ChangeDarkMode, (state, { darkMode }) => ({ ...state, darkMode })),
  on(ToggleDarkMode, (state) => ({ ...state, darkMode: !state.darkMode }))
);
