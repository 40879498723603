import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'axle-toggle[control]',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './axle-toggle.component.html'
})
export class AxleToggleComponent {
  @Input() control!: FormControl;
  @Input() disabled: boolean = false;
  @Input() label?: string;
  @Input() labelPos: 'before' | 'after' = 'after';
  @Input() size: 'small' | 'default' | 'large' = 'default';
}
