import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AxlePrimaryButtonComponent } from 'src/app/shared/components/buttons/primary-button/axle-primary-button.component';
import { selectLoginLogoUrl } from 'src/app/shared/state/theme/theme.selectors';
import { AxleResetPasswordInputComponent } from '../input/reset-password-input.component';
import { BehaviorSubject } from 'rxjs';
import { AxleResetPasswordSuccessComponent } from '../success/reset-password-success.component';
import { AxleBackButtonComponent } from 'src/app/shared/components/buttons/back-button/axle-back-button.component';

@Component({
  selector: 'axle-reset-password-container',
  standalone: true,
  imports: [
    CommonModule,
    AxlePrimaryButtonComponent,
    AxleBackButtonComponent,
    AxleResetPasswordInputComponent,
    AxleResetPasswordSuccessComponent
  ],
  templateUrl: './reset-password-container.component.html'
})
export class AxleResetPasswordContainerComponent {
  loginLogoSrc$ = this._store.select(selectLoginLogoUrl);
  resetPasswordSuccessful$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _router: Router,
    private _store: Store
  ) {}

  login() {
    this._router.navigate(['login'], { replaceUrl: true });
  }

  sendEmail() {
    this.loading$.next(true);
    setTimeout(() => {
      this.resetPasswordSuccessful$.next(true);
      this.loading$.next(false);
    }, 2000);
  }
}
