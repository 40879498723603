import { Action, ActionReducer } from '@ngrx/store';
import { Logout } from '../state/authentication/authentication.actions';

// Meta-reducer
export function clearState<State extends object>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State | undefined, action: Action) {
    if (action.type === Logout.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
