import { Theme } from '../../models/theme.models';

export interface ThemeState {
  currentTheme: Theme;
  darkMode: boolean;
}

export const initialState: ThemeState = {
  currentTheme: 'AXLE',
  darkMode: false
};
