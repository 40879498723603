<div class="shadow-axleGray rounded-b-xl">
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <h2
      cdkAccordionItemHeader
      class="bg-secondary-50 dark:bg-secondary-800 rounded-xl"
      (click)="accordionItem.toggle()"
      (keydown)="accordionItem.toggle()"
      tabindex="0">
      <button
        type="button"
        class="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-500 rounded-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 gap-3">
        <div class="flex flex-col items-start">
          <h5 class="text-lg font-medium tracking-tight text-gray-900 dark:text-white">{{ title }}</h5>
          <p *ngIf="subheader" class="text-sm text-left font-light text-gray-700 dark:text-gray-400">{{ subheader }}</p>
        </div>
        @if (accordionItem.expanded) {
          <svg class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"></path>
          </svg>
        } @else {
          <svg
            class="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
          </svg>
        }
      </button>
    </h2>
    <div
      cdkAccordionItemContent
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index"
      class="p-2 flex flex-col gap-3 rounded-b-xl max-h-24 overflow-auto border-gray-200 dark:border-gray-700 dark:bg-gray-900">
      <ng-content></ng-content>
    </div>
  </cdk-accordion-item>
</div>
