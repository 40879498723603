import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'axle-tile-button[header][subheader][icon]',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './axle-tile-button.component.html'
})
export class AxleTileButtonComponent {
  @Input() header!: string;
  @Input() subheader!: string;
  @Input() icon!: string;

  @Output() clicked = new EventEmitter<void>();

  tileClicked() {
    this.clicked.emit();
  }
}
