import { Component } from '@angular/core';
import { AxleAuthContainerComponent } from 'src/app/shared/components/containers/auth-container/auth-container.component';

@Component({
  selector: 'axle-events-container',
  standalone: true,
  imports: [AxleAuthContainerComponent],
  templateUrl: './events-container.component.html'
})
export class AxleEventsContainerComponent {}
