<div class="w-full mb-4 border border-black rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
  <input
    type="text"
    [formControl]="subjectControl"
    id="default-input"
    placeholder="Subject (optional)"
    class="border-0 text-gray-900 rounded-b-none rounded-t-lg outline-none text-sm focus:ring-0 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-0" />
  <div class="w-full h-[0.5px] bg-gray-300 dark:bg-gray-600"></div>
  <div class="px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">
    <label for="editor" class="sr-only">Add a note</label>
    <textarea
      id="editor"
      [formControl]="noteControl"
      rows="8"
      class="block w-full px-0 text-sm text-gray-800 outline-none bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
      placeholder="Start typing to add a note..."
      required></textarea>
  </div>
</div>
